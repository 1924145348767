<template>
	<div class="home">
		<div class="diyipai">
			<div class="item wirthbackgour">
				<div class="title_box">
					<div class="title">部门数据量维度</div>
					<div class="selectmouth">
						<div>月</div>
						<div>年</div>
					</div>
					<div class="block">
						<el-date-picker v-model="value1" type="date" size='small' placeholder="选择日期">
						</el-date-picker>
					</div>
				</div>
				<div style="font-size: 0.1rem;padding-left: 0.2rem;">单位：个</div>
				<div id="main" style="width:90%;height:80%;"></div>
			</div>
			<div class="item wirthbackgour">
				<div class="title_box">
					<div class="title">部门完结率维度</div>
				</div>
				<div id="main2" style="width:100%;height:80%;"></div>
			</div>
			<div class="item wirthbackgour">
				<div class="title_box">
					<div class="title">某部门近年统计</div>
				</div>
				<div style="font-size: 0.1rem;padding-left: 0.2rem;">单位：个</div>
				<div id="main3" style="width:90%;height:80%;"></div>
			</div>
		</div>
		<div class="dierpai">
			<div class="item wirthbackgour">
				<div class="title_box">
					<div class="title" style="margin-right: 4rem;">全区近年走势</div>
					<div class="selectmouth">
						<div>月</div>
						<div>年</div>
					</div>
					<div class="block">
						<el-date-picker v-model="value1" type="date" size='small' placeholder="选择日期">
						</el-date-picker>
					</div>
				</div>
				<div style="font-size: 0.1rem;padding-left: 0.2rem;">单位：个</div>
				<div id="main4" style="width:95%;height:78%;"></div>
			</div>
			<div class="item wirthbackgour">
				<div class="title_box">
					<div class="title" style="margin-right: 0.95rem;">其他</div>
					<div class="selectmouth">
						<div>月</div>
						<div>年</div>
					</div>
					<div class="block">
						<el-date-picker v-model="value1" type="date" size='small' placeholder="选择日期">
						</el-date-picker>
					</div>
				</div>
					<div style="font-size: 0.1rem;padding-left: 0.2rem;">单位：个</div>
					<div id="main5" style="width:95%;height:78%;"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import * as echarts from 'echarts'
	export default {
		data() {
			return {
				value1: ''
			}
		},
		mounted() {
			this.echart1()
			this.echart2()
			this.echart3()
			this.echart4()
			this.echart5()
		},
		methods: {
			echart1() {
				var myChart = echarts.init(document.getElementById('main'))
				var option = {
					title: {
						text: ''
					},
					legend: {
						right: '-0%',
						orient: 'vertical',
						top: '50%',
						data: ['提交量', '驳回量'],
						textStyle:{
									color:"#fff"
								}
					},

					color: ['#1e6adf', '#ff1600'],
					radar: {
						// shape: 'circle',
						indicator: [{
								name: '交通',
								max: 230
							},
							{
								name: '城建',
								max: 230
							},
							{
								name: '经济',
								max: 230
							},
							{
								name: '民政',
								max: 230
							},
							{
								name: '环境',
								max: 230
							},
							{
								name: '教科文卫',
								max: 230
							}
						]
					},
					series: [{
						name: 'Budget vs spending',
						type: 'radar',
						data: [{
								value: [98, 121, 63, 79, 98, 76],
								name: '提交量',
								label: {
									show: true,
									formatter: function(params) {
										return params.value;
									}
								}
							},
							{
								value: [200, 158, 186, 190, 230, 180],
								name: '驳回量',
								label: {
									show: true,
									formatter: function(params) {
										return params.value;
									}
								}
							}
						]
					}]
				}
				myChart.setOption(option)
			},

			echart2() {
				var myChart = echarts.init(document.getElementById('main2'))
				var option = {
					xAxis: {
						type: 'category',
						data: ['经济', '城建', '教科文卫', '交通', '民政', '环境'],
						axisTick: {
							alignWithLabel: true
						},
						axisLabel: {
							interval: 0,
							textStyle:{
										color:"#fff"
									}
						}
					},
					tooltip: {
					  valueFormatter: function (value) {
					    return value + '%';
					  }
					},
					yAxis: {
						type: 'value',
						min: 0,
						max: 70,
						axisLabel: {
							textStyle:{
										color:"#fff"
									},
							formatter: function(value) {
								var texts = [];
								if (value == 0) {
									texts.push('0%');
								} else if (value <= 10) {
									texts.push('10%');
								} else if (value <= 20) {
									texts.push('20%');
								} else if (value <= 30) {
									texts.push('30%');
								} else if (value <= 40) {
									texts.push('40%');
								} else if (value <= 50) {
									texts.push('50%');
								} else if (value <= 60) {
									texts.push('60%');
								} else if (value <= 70) {
									texts.push('70%');
								}
								return texts;
							}
						}
					},
					color: ['#0aeada'],
					grid: {
						top: '10%',
						bottom: '10%',
						left: '15%',
						right: '10%'
					},
					series: [{
						data: [69, 21, 55, 48, 39, 49],
						type: 'bar',
						showBackground: true,
						backgroundStyle: {
							color: 'rgba(180, 180, 180, 0.2)'
						}
					}]
				}
				myChart.setOption(option)
			},

			echart3() {
				var myChart = echarts.init(document.getElementById('main3'))
				var option = {
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'cross',
							crossStyle: {
								color: '#999'
							}
						}
					},
					legend: {
						data: ['结案量', '滞留量'],
						textStyle:{
								color:"#fff"
							}
					},
					grid: {
						top: '10%',
						bottom: '15%',
						left: '15%',
						right: '0%'
					},
					color: ['#1e6adf', '#ff1600'],
					xAxis: [{
						type: 'category',
						data: ['2017年', '2018年', '2019年', '2020年', '2021年'],
						axisLabel: {
							textStyle:{
									color:"#fff"
								}
						},
						axisPointer: {
							type: 'shadow',
						}
					}],
					yAxis: [{
						type: 'value',
						name: '',
						min: 0,
						max: 250,
						interval: 50,
						axisLabel: {
							textStyle:{
									color:"#fff"
								},
							formatter: '{value}'
						}
					}],
					series: [{
							name: '结案量',
							type: 'bar',
							tooltip: {
								valueFormatter: function(value) {
									return value + '个';
								}
							},
							label: {
							        show: true,
							        position: 'top'
							      },
							data: [160, 60, 230, 100, 130]
						},
						{
							name: '滞留量',
							type: 'bar',
							label: {
							        show: true,
							        position: 'top'
							      },
							tooltip: {
								valueFormatter: function(value) {
									return value + '个';
								}
							},
							data: [20, 15, 31, 28, 34]
						}
					]
				}
				myChart.setOption(option)


			},
			
			echart4() {
				var myChart = echarts.init(document.getElementById('main4'))
				var option = {
					xAxis: {
					    type: 'category',
					    data: ['2010年', '2011年', '2012年', '2013年', '2014年', '2015年', '2016年','2017年','2018年','2019年','2020年','2021年'],
						axisLabel:{
							textStyle:{
									color:"#fff"
								},
						}
					  },
					  yAxis: {
					    type: 'value',
						axisLabel:{
							textStyle:{
									color:"#fff"
								},
						}
					  },
					  legend: {
					  	data: ['提交量', '采纳量'],
						textStyle:{
								color:"#fff"
							},
					  },
					  tooltip: {
					    valueFormatter: function (value) {
					      return value + '个';
					    }
					  },
					  grid: {
					  	top: '15%',
					  	bottom: '15%',
					  	left: '5%',
					  	right: '0%'
					  },
					  color: ['#1e6adf', '#0aeada'],
					  series: [
					    {
						  name: '提交量',
					      data: [30,60,50,60,40,50,42,50,40,50,60,60],
					      type: 'line'
					    },
					    {
						  name: '采纳量',
					      data: [22,45,15,25,18,17,37,35,28,32,34,49],
					      type: 'line'
					    }
					  ]
				}
				myChart.setOption(option)
			
			
			},
			echart5() {
				var myChart = echarts.init(document.getElementById('main5'))
				var option = {
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'cross',
							crossStyle: {
								color: '#999'
							}
						}
					},
					legend: {
						data: ['委员人均管理群众数', '群众人均提交量'],
						textStyle:{
								color:"#fff"
							},
					},
					grid: {
						top: '10%',
						bottom: '10%',
						left: '10%',
						right: '0%'
					},
					color: ['#ff1600','#68bbc4'],
					xAxis: [{
						type: 'category',
						data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月','8月','9月','10月','11月','12月'],
						axisPointer: {
							type: 'shadow',
							textStyle:{
									color:"#fff"
								},
						}
					}],
					yAxis: [{
						type: 'value',
						name: '',
						min: 0,
						max: 15,
						interval: 3,
						axisLabel: {
							textStyle:{
									color:"#fff"
								},
							formatter: '{value}'
						}
					}],
					series: [{
							name: '委员人均管理群众数',
							type: 'bar',
							tooltip: {
								valueFormatter: function(value) {
									return value + '人';
								}
							},
							label: {
							        show: true,
							        position: 'top'
							      },
							data: [4,6,6,8,7,11,9,13,8,7,9,10]
						},
						{
							name: '群众人均提交量',
							type: 'bar',
							tooltip: {
								valueFormatter: function(value) {
									return value + '个';
								}
							},
							label: {
							        show: true,
							        position: 'top'
							      },
							data: [8,6,3,9,2,8,6,7,6,5,9,8]
						}
					]
				}
				myChart.setOption(option)
			
			
			},
		},

	}
</script>

<style lang="less" scoped>
	.home {
		.title_box {
			display: flex;
			align-items: center;
			width: 100%;
			padding: 10px 0.1rem;
			font-size: 0.1rem;
		
			.title {
				width: fit-content;
				color: rgb(11, 90, 245);
				border: 1px solid rgb(11, 90, 245);
				padding: 4px 0.2rem;
				border-radius: 5px;
				margin-right: 0.2rem;
			background-color: #0b5af5;
			color: #fff;
			}
		}
		.selectmouth {
			display: flex;
			align-items: center;
			width: 0.8rem;
		
			div {
				flex: 1;
				text-align: center;
				padding: 3px 0;
				cursor: pointer;
			}
		
			div:nth-child(1) {
				border-top-left-radius: 5px;
				border-bottom-left-radius: 5px;
				border: 1px solid rgb(11, 90, 245);
				color: rgb(11, 90, 245);
			}
		
			div:nth-last-child(1) {
				border-top-right-radius: 5px;
				border-bottom-right-radius: 5px;
				border-top: 1px solid #c0c0c0;
				border-bottom: 1px solid #c0c0c0;
				border-right: 1px solid #c0c0c0;
			}
		}
		.block {
			margin-left: 0.1rem;
			width: 0.65rem;
		
			/deep/.el-date-editor.el-input {
				width: 100%;
			}
		}
		.diyipai {
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 38vh;
			margin-top: 2vh;
			margin-bottom: 2vh;

			.item {
				width: 32%;
				height: 100%;
				border: 1px solid #c0c0c0;
				border-radius: 10px;
				box-shadow: 0 0 5px #c0c0c0;

				

			}
		}

		.dierpai {
			height: 38vh;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.item {
				width: 32%;
				height: 100%;
				border: 1px solid #c0c0c0;
				border-radius: 10px;
				box-shadow: 0 0 5px #c0c0c0;
			}
			.item:nth-child(1){
				width: 66%;
			}
		}
	}
	/deep/ .block{
		input{
			background: none !important;
		}
	}
</style>
